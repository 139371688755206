function ready(fn) {
    if (document.readyState != 'loading'){
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}


/*
 *	COOKIEJS
 */

window.NtrigaConsent = {
    marketing: false,
};

ready(function() {
    // Vars
    var btn_show = document.querySelector('#ntr-cookie-show');
    var popup = document.querySelector('#ntr-cookies');
    var btn_accept = document.querySelector('#ntr-cookie-accept');
    var btn_decline = document.querySelector('#ntr-cookie-decline');

    // Get consent cookie
    if (Cookies.get('ntriga-cookie-marketing')) {
        NtrigaConsent.marketing = (Cookies.get('ntriga-cookie-marketing') === 'true' || Cookies.get('ntriga-cookie-marketing') === true);
    }

    if (popup) {
        // Show?
        if (!Cookies.get('ntriga-cookie-chosen')) {
            popup.classList.remove('hidden');
        }

        // Accept
        btn_accept.addEventListener('click', function() {
            NtrigaConsent.marketing = true
            consentUpdated();
            popup.classList.add('hidden');
        }, false);

        // Refuse
        btn_decline.addEventListener('click', function() {
            NtrigaConsent.marketing = false;
            consentUpdated();
            popup.classList.add('hidden');
        });

        // Show popup
        btn_show.addEventListener('click', function() {
            popup.classList.remove('hidden');
        });
    }
});

function consentUpdated() {
    Cookies.set('ntriga-cookie-chosen', true);
    Cookies.set('ntriga-cookie-marketing', NtrigaConsent.marketing);

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({'event':'cookie_consent_marketing'});
    }
}